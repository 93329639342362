import {
  wixCodeItemsToProGallery,
  proGalleryItemsToWixCode,
  proGalleryItemToWixCode,
} from '@wix/pro-gallery-items-formatter/dist/src/wixCode';

class WixCodeApi {
  constructor() {
    this.generateApi = this.generateApi.bind(this);
    this.onCurrentItemChanged = this.onCurrentItemChanged.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.setStyleParams = this.setStyleParams.bind(this);
    this.setClickCallback = this.setClickCallback.bind(this);
    this.setItemChangedCallback = this.setItemChangedCallback.bind(this);

    this.manualStyleParams = {};
    this.itemClickedCallbacks = [];
    this.itemChangedCallbacks = [];
    this.clickedIdx = -1;
  }

  setClickCallback(callback) {
    if (typeof callback === 'function') {
      this.itemClickedCallbacks.push(callback);
    }
  }

  onItemClicked(item) {
    const event = {
      action: 'itemClicked',
      id: item.id,
      imageIndex: item.idx,
      item: proGalleryItemToWixCode(item.dto),
    };
    //activate open callbacks
    try {
      for (let i = 0; i < this.itemClickedCallbacks.length; i++) {
        const callback = this.itemClickedCallbacks[i];
        if (typeof callback === 'function') {
          callback(event);
        }
      }
    } catch (e) {
      console.warn('Gallery failed to activate onItemClicked callbacks', e);
    }
  }

  setItemChangedCallback(callback) {
    if (typeof callback === 'function') {
      this.itemChangedCallbacks.push(callback);
    }
  }

  onCurrentItemChanged(item) {
    try {
      const _item = {
        compId: this.compId,
        event: {
          action: 'imageChanged',
          id: item.id,
          imageIndex: item.idx,
          item: proGalleryItemToWixCode(item.dto),
        },
      };
      for (let i = 0; i < this.itemChangedCallbacks.length; i++) {
        const callback = this.itemChangedCallbacks[i];
        if (typeof callback === 'function') {
          callback(_item.event);
        }
      }
    } catch (e) {
      //
    }
  }

  setStyleParams(styleParams) {
    const mapStyleParams = _styleParams => {
      const mappedStyleParams = {};
      const keys = Object.keys({ ..._styleParams });
      for (const key of keys) {
        mappedStyleParams[key] = mapStyleParam(key, _styleParams[key]);
      }
      return mappedStyleParams;
    };

    const mapStyleParam = (key, val) => {
      let newVal;
      switch (key) {
        case 'itemClick':
          switch (val) {
            default:
            case 'none':
            case 'disabled':
              newVal = 'nothing';
              break;
            case 'expand':
            case 'fullscreen':
            case 'zoomMode':
              newVal = 'expand';
              break;
            case 'link':
            case 'goToLink':
              newVal = 'link';
              break;
          }
          break;
        default: {
          newVal = val;
        }
      }
      return newVal;
    };

    try {
      this.manualStyleParams = mapStyleParams(styleParams);
    } catch (e) {
      console.error('Failed to set styleParams', e);
      return false;
    }
  }

  generateApi({
    proGalleryStore,
    setNewItems,
    setNewStyleParams,
    setClickedIdx,
  }) {
    const {
      setStyleParams,
      manualStyleParams,
      setClickCallback,
      setItemChangedCallback,
    } = this;

    return {
      get type() {
        return '$w.Gallery';
      },
      get galleryCapabilities() {
        return {
          isPlayable: false,
          hasCurrentItem: false,
          hasNavigationButtons: false,
          supportsAllMediaTypes: true,
        };
      },
      set items(images) {
        proGalleryStore.setItems(wixCodeItemsToProGallery(images));
        proGalleryStore.totalItemsCount = images.length;
        proGalleryStore.itemsSrc = 'wixCode';
        const proGalleryStoreItems = proGalleryStore.getItemsWithoutWixVideos();
        setNewItems(proGalleryStoreItems);
        proGalleryStore
          .getItemsWithWixVideosUrls(proGalleryStore.getItems())
          .then(setNewItems);
      },
      get items() {
        return proGalleryItemsToWixCode(proGalleryStore.getItems());
      },
      onItemClicked: callback => setClickCallback(callback),
      onCurrentItemChanged: callback => setItemChangedCallback(callback),
      expandItem(itemIdx) {
        setClickedIdx(itemIdx);
      },
      set clickAction(action) {
        setStyleParams({ itemClick: action }); //action is: 'none' / 'expand' / 'link'
      },
      get options() {
        return { styleParams: manualStyleParams };
      },
      set options({ styleParams }) {
        setStyleParams(styleParams);
        setNewStyleParams(manualStyleParams);
      },
      toJSON: () => ({
        items: proGalleryStore.getItems(),
        clickAction: manualStyleParams.itemClick,
      }),
    };
  }
}

export default new WixCodeApi();
